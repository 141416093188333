<template>
  <v-container fluid>
    <v-toolbar rounded>
      <span class="text-h5">Accès à la nouvelle hotline</span>

      <v-spacer></v-spacer>

      <hotline-access-form
          v-model="creatingHotlineAccess"
          :clientList="clientList"
          actionLabel="Créer"
          title="Ouvrir l'accès à la hotline"
          @save="saveConfiguration"
      >
        <template v-slot:activator="{attrs, on}">
          <v-btn v-on="on" v-bind:attrs="attrs" color="primary" small @click="reset">Créer</v-btn>
        </template>
      </hotline-access-form>

    </v-toolbar>

    <v-card class="mt-4">
      <template v-if="!loadingList && hotlineAccessList.length == 0">
        <v-list-item class="ma-0 text-center">
          <v-list-item-title class="subtitle-1">Aucun accès paramétré</v-list-item-title>
        </v-list-item>
      </template>
      <template v-if="loadingList">
        <div class="d-flex flex-column align-center justify-center pa-8">
          <v-progress-circular size="32" indeterminate color="primary"></v-progress-circular>
        </div>
      </template>
      <template v-if="hotlineAccessList.length > 0">
        <v-list>
          <template v-for="(hotlineAccess, i) in hotlineAccessList">
            <v-list-item :key="'hotline-access-' + i">

              <template #default>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ hotlineAccess.title }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ hotlineAccess.list.length }} bases
                  </v-list-item-subtitle>
                </v-list-item-content>

                <div class="d-flex flex-row align-center">
                  <v-switch v-model="hotlineAccess.active" class="mr-12" label="Actif" @change="confirmActive = i"></v-switch>

                  <hotline-access-form
                      v-model="creatingHotlineAccess"
                      :clientList="clientList"
                      actionLabel="Editer"
                      actionColor="warning"
                      title="Edition d'un message d'accueil"
                      @save="saveConfiguration(creatingHotlineAccess, i)"
                  >
                    <template v-slot:activator="{attrs, on}">
                      <v-btn v-on="on" :attrs="attrs" icon @click="editConfiguration(i)">
                        <v-icon>mdi-square-edit-outline</v-icon>
                      </v-btn>
                    </template>
                  </hotline-access-form>

                  <v-btn icon>
                    <v-icon @click="confirmDelete = i">mdi-delete</v-icon>
                  </v-btn>
                </div>
              </template>

            </v-list-item>

            <v-divider :key="'hotline-access-separator-' + i"></v-divider>
          </template>

        </v-list>
      </template>
    </v-card>


    <v-dialog :value="confirmDelete !== null" width="25%">
      <v-card>
        <v-toolbar color="danger" dense>
          <v-toolbar-title>Confirmation de suppression</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4">
          <span>Êtes-vous sûr(e) de vouloir supprimer cet accès ?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="confirmDelete = null">Annuler</v-btn>
          <v-btn color="error" :loading="loadingDelete" @click="deleteConfiguration">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="confirmActive !== null" :value="confirmActive !== null" width="25%" @click:outside="cancelActivation">
      <v-card>
        <v-toolbar color="danger" dense>
          <v-toolbar-title>Confirmation d'activation</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4">
          <span>Êtes-vous sûr(e) de vouloir {{ hotlineAccessList[confirmActive].active ? "" : "dés"}}activer cet accès ?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancelActivation">Annuler</v-btn>
          <v-btn color="warning" :loading="loadingDelete" @click="toggleActive">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import PlaneteOnlineService from "@/Services/PlaneteOnlineService";
import {SVSHomepage, SVSHotline} from "@/Services/SupportVisionService";

export default {
  name: "Index",
  components: {
    HotlineAccessForm: () => import("@/Components/Views/SupportVision/Hotline/Form")
  },
  data() {
    return {
      clientList: [],
      loadingList: false,
      hotlineAccessList: [],
      creatingHotlineAccess: {},

      editing: null,

      confirmDelete: null,
      confirmActive: null,
      loadingDelete: false

    }
  },
  methods: {
    async saveConfiguration(hotlineAccess, index) {
      let response
      if (this.editing === null) {
        response = await SVSHotline.postHotlineAccess(hotlineAccess)
        this.hotlineAccessList.push(response)
      } else {
        response = await SVSHotline.putHotlineAccess(hotlineAccess)
        this.reset()
        this.$set(this.hotlineAccessList, index, response.data)
      }
      if (response) {
        this.$nSuccess("Accès à la hotline" + (this.editing === null ? "créé" : "modifié"))
      } else {
        this.$nError("Impossible de " + (this.editing === null ? "créer" : "modifier") + " l'access à la hotline'")
      }
    },
    editConfiguration(index) {
      this.editing = index
      this.creatingHotlineAccess = JSON.parse(JSON.stringify(this.hotlineAccessList[index]))
    },
    async deleteConfiguration() {
      let index = this.confirmDelete
      let _id = this.hotlineAccessList[index]._id
      this.loadingDelete = true
      let deleted = await SVSHotline.removeHotlineAccess(_id)
      if (deleted) {
        let index = this.hotlineAccessList.findIndex(a => a._id == _id)
        this.hotlineAccessList.splice(index, 1)
        this.$nSuccess("Accès supprimé")
      } else {
        this.$nError("Accès non supprimé")
      }
      this.confirmDelete = null
      this.loadingDelete = false
    },
    async toggleActive() {
      let index = this.confirmActive
      let toEdit = this.hotlineAccessList[index]
      let response = await SVSHotline.putHotlineAccess(toEdit)
      this.confirmActive = null
    },
    cancelActivation() {
      this.hotlineAccessList[this.confirmActive].active = !this.hotlineAccessList[this.confirmActive].active
      this.confirmActive = null
    },
    reset() {
      this.editing = null
      this.creatingHotlineAccess = {
        title: "",
        list: [],
        active: false,
        start: null,
        end: null
      }
    }

  },
  async mounted() {
    this.loadingList = true
    this.clientList = await PlaneteOnlineService.getBases(true)
    this.hotlineAccessList = await SVSHotline.getAllHotlineAccessList()
    this.loadingList = false
  }
}
</script>

<style scoped>

</style>